<template>
  <q-form ref="editForm">
    <c-card title="관련자료" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-12">
          <c-upload
            :attachInfo="attachInfo"
            :editable="editable && popupParam.selfInspectionStatusCd == 'SISC000002'"
            label="자체감사 관련자료">
          </c-upload>
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
export default {
  name: 'hazop-plan-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SELF_INSPECTION',
        taskKey: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.$set(this.attachInfo, 'taskKey', this.popupParam.selfInspectionResultId)
    },
  }
};
</script>
